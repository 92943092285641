<template>
  <div :style="'height:' + minHeight + 'px;overflow:scroll'">
    <div class="topImage">
      <div class="topInfo">
        <div class="topTitle">指云端智慧医疗服务平台</div>
        <div class="topDesc">
          为患者、医院、医生提供高效、安全、专业的影像服务
        </div>
      </div>
    </div>
    <div class="links">
      <router-link
        class="productLink"
        to="/phone/product/couldDicom"
        active-class="linkActive"
      >
        云影像
        <div class="line"></div>
      </router-link>
      <router-link
        class="productLink"
        to="/phone/product/dicomCloudStorage"
        active-class="linkActive"
      >
        影像云存储
        <div class="line"></div>
      </router-link>
      <router-link
        class="productLink"
        to="/phone/product/regionalCloudDicom"
        active-class="linkActive"
      >
        医疗协同
        <div class="line"></div>
      </router-link>
    </div>
    <router-view></router-view>
    <smallfoot></smallfoot>
  </div>
</template>

<style lang="less" scoped>
.topImage {
  display: inline-block;
  height: 390px;
  width: 100%;
  background: url('../../../assets/images/web/product/bejjing@2x.png') no-repeat;
  background-size: 100% 100%;
  .topInfo {
    // width: 1200px;
    margin: 0 auto;
    height: 100%;
    padding-left: 40px;
    .topTitle {
      font-size: 32px;
      font-family: FZZongYi-M05S;
      font-weight: 400;
      letter-spacing: 2px;
      color: #ffffff;
      margin-top: 108px;
    }
    .topDesc {
      letter-spacing: 2px;
      font-size: 24px;
      line-height: 40px;
      width: 450px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-top: 43px;
    }
  }
}

.links {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 65px;
  margin-top: 43px;
  margin-left: 61px;
  .productLink {
    width: fit-content;
    height: 52px;
    font-size: 29px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #929292;
    margin-right: 65px;
    .line {
      width: 70px;
      height: 4px;
      background: white;
      margin: 0 auto;
      margin-top: 19px;
    }
  }
  .linkActive {
    color: #0c2948;
    font-weight: bold;
    .line {
      width: 70px;
      height: 4px;
      background: #0c2948;
      margin: 0 auto;
      margin-top: 19px;
    }
  }
}
</style>

<script>
import smallfoot from '../../../components/Appfooter/index.vue'
export default {
  components: { smallfoot },
  data() {
    return {
      minHeight: 0
    }
  },
  mounted(){
     this.minHeight = this.$store.state.minHeight
  }
}
</script>
